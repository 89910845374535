<template>
  <v-chip-group column show-arrows>
    <!--  status -->
    <v-tooltip :bottom="true" v-bind="$attrs" v-on="$listeners">
      <template v-slot:activator="{ attrs, on }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          :outlined="outlinedStatus"
          :small="smallChip"
          :color="statusIconColorClass"
          >{{ statusText }}</v-chip
        >
      </template>
      {{ projectStatusInfo }}
    </v-tooltip>

    <!-- Overdue -->
    <v-chip
      v-if="isOverdue"
      :outlined="outlinedStatus"
      :small="smallChip"
      :color="overdueIconClass"
      >{{ overdueText }}</v-chip
    >
  </v-chip-group>
</template>

<script>
import {
  findProjectState,
  findProjectStateColorClass,
  projectOverdueColorClass
} from "@/model/workflow/project/projectModel";

export default {
  name: "ProjectStatus",
  props: {
    /**
     * @type {{projectId: Number, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}}
     */
    project: undefined,

    /**
     * is a small chip
     */
    smallChip: {
      type: Boolean,
      default: true
    },

    /**
     * is dark Chip
     */
    darkChip: {
      type: Boolean,
      default: true
    },

    /**
     * is outlined Status
     */
    outlinedStatus: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     * project status Icon Color Class
     * @return {String|string|undefined}
     */
    statusIconColorClass() {
      return findProjectStateColorClass(this.project?.status ?? -1);
    },

    /**
     * v status Text
     * @return {string|string}
     */
    statusText() {
      return findProjectState(this.project?.status ?? -1)?.name ?? "";
    },

    /**
     * is project Overdue
     * @return {Boolean|boolean} true if a project is overdue
     */
    isOverdue() {
      return this.project?.isOverdue ?? false;
    },

    /**
     * Overdue Icon Class
     * @return {String|string}
     */
    overdueIconClass() {
      return projectOverdueColorClass;
    },

    /**
     * overdue Text
     * @return {String|string} overdue Text
     */
    overdueText() {
      return "Overdue";
    },

    /**
     * project Status Info
     * @return {String|string} project Status Info
     */
    projectStatusInfo() {
      return this.project?.statusInfo;
    }
  }
};
</script>
